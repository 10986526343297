body {
  margin: 0;
  padding: 0;
}

.componentApp {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  align-self: center;
  margin-bottom: 25px;
  cursor: pointer;
}
.componentApp:hover {
  opacity: 0.7;
}

.componentApp input {
  display: none;
}

.crop-container {
  width: 100%;
  height: 400px;
  position: relative;
}

.controls {
  margin: auto;
  margin-top: 10px;
  width: 50%;
  /*display: flex;*/
  align-items: center;
}

.slider {
  padding: 30px 0px;
}

.button {
  text-align: center;
}

.MuiDialog-root-48 {
  width: 500px !important;
  height: 570px !important;
  top: 19% !important;
  left: 37% !important;
  z-index: 9999 !important;
}
